import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./OverviewCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { Participants } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type OverviewType = {
  participants: {
    participant: {
      preferredLanguage: string;
    };
    answers: {};
    entryStatus: Number;
  }[];
};

type ParticipantData = {
  participantData: {
    sk: string;
    dataKey: string;
    entryStatus: number;
    creationTime: string;
  }[];
};

type DateSelectionForm = {
  month: Date;
};

export const REPORT_BEGINS = "July 2023";

function Overview() {
  const ENGLISH_TOTAL_PREV = 5935;
  const ENGLISH_COMPLETED_PREV = 4392;
  const FRENCH_TOTAL_PREV = 270;
  const FRENCH_COMPLETED_PREV = 177;

  const { handleSubmit, control } = useForm<DateSelectionForm>({ mode: "onChange" });
  const getMonth = (month: number) => (month + 1).toLocaleString().padStart(2, "0");
  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
    month: getMonth(new Date().getMonth()),
  });
  const [filterTable, setFilterTable] = useState("");
  const [showTotal, setShowTotal] = useState(false);
  const { isLoading: isLoadingOverview, data: overview } = useQuery(
    ["overview", filter],
    async () => {
      const res: OverviewType = await campaignClient.call("overview", filter);
      return res.participants;
    }
  );

  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    ["getParticipants", filter],
    async () => {
      const res: Participants = await campaignClient.call("getParticipants", {});
      return res.participants;
    }
  );

  const { isLoading: isLoadingParticipantData, data: participantData } = useQuery(
    ["getParticipantData", filter],
    async () => {
      const res: ParticipantData = await campaignClient.call("getParticipantData", {});
      return res.participantData?.filter((p) =>
        dayjs(new Date(REPORT_BEGINS)).isAfter(new Date(p.creationTime))
      );
    }
  );

  const getCompletedRate = (completions: number, total: number) => {
    return +Math.round((100 * completions) / total);
  };

  const data = useMemo(() => {
    let englishTotal = 0;
    let completionsEn = 0;
    let frenchTotal = 0;
    let completionsFr = 0;

    if (showTotal && participants?.length && participantData?.length) {
      englishTotal = ENGLISH_TOTAL_PREV;
      completionsEn = ENGLISH_COMPLETED_PREV;
      frenchTotal = FRENCH_TOTAL_PREV;
      completionsFr = FRENCH_COMPLETED_PREV;
      const englishFiltered = participants.filter((p) => p?.preferredLanguage === "en");
      const frenchFiltered = participants.filter((p) => p.preferredLanguage === "fr");

      englishTotal += +englishFiltered?.length;
      completionsEn += englishFiltered.filter(
        (p: any) => participantData.find((d) => d.sk === p.sessionKey)?.entryStatus === 2
      ).length;

      frenchTotal += +frenchFiltered?.length;
      completionsFr += frenchFiltered.filter(
        (p: any) => participantData.find((d) => d.sk === p.sessionKey)?.entryStatus === 2
      ).length;
    } else if (!showTotal && overview && overview.length > 0) {
      const englishFiltered = overview.filter((p) => p?.participant?.preferredLanguage === "en");
      const frenchFiltered = overview.filter((p) => p?.participant.preferredLanguage === "fr");

      englishTotal = +englishFiltered?.length;
      completionsEn = englishFiltered.filter((p: any) => p.entryStatus === 2).length;

      frenchTotal = +frenchFiltered?.length;
      completionsFr = frenchFiltered.filter((p: any) => p.entryStatus === 2).length;
    }

    const notCompletedEn = englishTotal - completionsEn;
    const completedRate = getCompletedRate(completionsEn, englishTotal);
    const dropOff = 100 - +completedRate;

    const notCompletedFr = frenchTotal - completionsFr;
    const completedRateFr = getCompletedRate(+completionsFr, frenchTotal);
    const dropOffFr = 100 - +completedRateFr;
    return [
      {
        language: "English",
        total: englishTotal || 0,
        completions: completionsEn ? completionsEn : 0,
        completeRate: `${completedRate ? completedRate : 0}%`,
        notCompleted: notCompletedEn,
        dropOff: `${dropOff ? dropOff : 0}%`,
      },
      {
        language: "French",
        total: frenchTotal || 0,
        completions: completionsFr ? completionsFr : 0,
        completeRate: `${completedRateFr ? completedRateFr : 0}%`,
        notCompleted: notCompletedFr,
        dropOff: `${dropOffFr ? dropOffFr : 0}%`,
      },
    ];
  }, [overview, filter, showTotal, participants, participantData]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp) => {
        return {
          Language: rp.language,
          "TOTAL PARTICIPANTS": rp.total,
          "FULL QUIZ COMPLETIONS": rp.completions,
          "COMPLETION RATE %": rp.completeRate,
          "QUIZ NOT COMPLETED": rp.notCompleted,
          "DROP OFF RATE %": rp.dropOff,
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  const handleFilter = async (date: DateSelectionForm) => {
    setShowTotal(false);

    const year = date.month ? date.month.getFullYear() : new Date().getFullYear();
    const month = getMonth(date.month ? date.month.getMonth() : new Date().getMonth());
    setFilter({ month, year });
  };

  if (isLoadingOverview || isLoadingParticipants || isLoadingParticipantData) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Future Launch Discovery Quiz Results</h2>

        {!!overview?.length && (
          <div className="main__actions">
            <div className="search search--alt">
              <div className="search__row search__row--input">
                <CSVLink
                  filename={`Future_Launch_Discovery_Quiz-Report-${new Date().toLocaleDateString()}`}
                  className="btn btn--medium btn--mobile-small"
                  data={reportData ? reportData : ""}
                  asyncOnClick={true}
                  target="_blank">
                  Download Report
                </CSVLink>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="main__body">
        <div className="tabs js-tabs" style={{ display: "flex" }}>
          <form>
            <div className="filter">
              <div className="date__control">
                <Controller
                  control={control}
                  name="month"
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      selected={field.value || new Date()}
                      minDate={new Date(REPORT_BEGINS)}
                      maxDate={new Date()}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                    />
                  )}
                />
              </div>
              <div className="twoButtons">
                <button className="btn" type="submit" onClick={handleSubmit(handleFilter)}>
                  Filter
                </button>
                <button
                  className="btn"
                  type="button"
                  onClick={() => {
                    setShowTotal(true);
                  }}>
                  Show Total
                </button>
              </div>
            </div>
          </form>
          {!!overview?.length && (
            <div className="search search--alt search--medium main__search">
              <div className="search__row">
                <label htmlFor="q" className="hidden">
                  Search
                </label>
                <input
                  type="search"
                  name="q"
                  id="q"
                  value={filterTable}
                  placeholder="Search"
                  className="search__field"
                  onChange={(e) => setFilterTable(e.target.value)}></input>
                <button className="search__btn">
                  <svg className="ico-search">
                    <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
        <br />
        <div className="tabs js-tabs">
          <div className="info-box">
            <p>
              Total entries{" "}
              {showTotal ? (
                <></>
              ) : (
                <>
                  for{" "}
                  {dayjs()
                    .month(+filter.month - 1)
                    .format("MMMM")}
                </>
              )}
            </p>
            <h1>
              {showTotal
                ? participants && participants?.length + ENGLISH_TOTAL_PREV + FRENCH_TOTAL_PREV
                : overview?.length || 0}
            </h1>
          </div>
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
          />
        </div>
      </div>
      {!overview?.length && <p>Currently there are no any participants to display.</p>}
    </>
  );
}

export default Overview;
