import { Row, TableProps } from "react-table"
import { QUESTIONS } from "./questions"

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1
        return nmb
      },
    },
    {
      Header: "Language",
      accessor: "language",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].language
          ? TableInfo.data[TableInfo.row.index].language
          : "N/A"
      },
    },
    {
      Header: "Entry Date",
      accessor: "entryDate",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1
        if (rowB.values[columnId] > rowA.values[columnId]) return -1
        return 0
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].entryDate
          ? TableInfo.data[TableInfo.row.index].entryDate.toLocaleString()
          : ""
        return dateString
      },
    },
    {
      Header: "Entry Time",
      sortType: "datetime",
      accessor: "entryTime",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].entryTime.toLocaleString()
        return dateString
      },
    },
    {
      Header: "Full Name",
      accessor: "fullName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].fullName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].fullName}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].gender ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].gender}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: QUESTIONS.optin,
      accessor: "optin",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].optin ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].optin}
          </a>
        ) : (
          "N/A"
        )
      },
    },

    {
      Header: QUESTIONS.q1,
      accessor: "q1",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q1
          ? TableInfo.data[TableInfo.row.index].q1
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q2,
      accessor: "q2",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q2
          ? TableInfo.data[TableInfo.row.index].q2
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q3,
      accessor: "q3",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q3
          ? TableInfo.data[TableInfo.row.index].q3
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q4,
      accessor: "q4",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q4
          ? TableInfo.data[TableInfo.row.index].q4
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q5,
      accessor: "q5",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q5
          ? TableInfo.data[TableInfo.row.index].q5
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q6,
      accessor: "q6",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q6
          ? TableInfo.data[TableInfo.row.index].q6
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q7,
      accessor: "q7",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q7
          ? TableInfo.data[TableInfo.row.index].q7
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q8,
      accessor: "q8",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q8
          ? TableInfo.data[TableInfo.row.index].q8
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q9,
      accessor: "q9",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q9
          ? TableInfo.data[TableInfo.row.index].q9
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q10,
      accessor: "q10",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q10
          ? TableInfo.data[TableInfo.row.index].q10
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q11,
      accessor: "q11",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q11
          ? TableInfo.data[TableInfo.row.index].q11
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q12,
      accessor: "q12",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q12
          ? TableInfo.data[TableInfo.row.index].q12
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q13,
      accessor: "q13",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q13
          ? TableInfo.data[TableInfo.row.index].q13
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q14,
      accessor: "q14",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q14
          ? TableInfo.data[TableInfo.row.index].q14
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q15,
      accessor: "q15",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q15
          ? TableInfo.data[TableInfo.row.index].q15
          : "N/A"
      },
    },
    {
      Header: QUESTIONS.q16,
      accessor: "q16",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].q16
          ? TableInfo.data[TableInfo.row.index].q16
          : "N/A"
      },
    },

    {
      Header: QUESTIONS.rules,
      accessor: "rules",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].rules}
          </a>
        )
      },
    },
    {
      Header: QUESTIONS.rules3,
      accessor: "rules3",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].rules3}
          </a>
        )
      },
    },
    {
      Header: QUESTIONS.rules4,
      accessor: "rules4",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].rules4}
          </a>
        )
      },
    },
  ]
  return COLUMNS
}
