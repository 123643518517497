import { SPRYCampaignClient } from "@spry/campaign-client"
import axios from "axios"

export interface IEmailTemplate {
  campaignKey: string
  language: string
  emailTemplateName: string
  emailTemplateKey?: string | null
  subject?: string
  htmlBody?: string | null
  textBody?: string | null
}

export interface IUser {
  firstName?: string
  lastName?: string
  email?: string
  code?: string
  username?: string
  password?: string
  token?: string
}

const endPoint = process.env.REACT_APP_API_ENDPOINT || "http://localhost:31337/admin"

const publicEndpoint =
  process.env.REACT_APP_API_PUBLIC_ENDPOINT || "http://localhost:31337/api"

const campaignClient = new SPRYCampaignClient(axios, endPoint)

const publicCampaignClient = new SPRYCampaignClient(axios, publicEndpoint!)

const setupApiClient = (token: string) => {
  campaignClient.setAuthorization(token)
}

export const signup = async (data: IUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/signup`,
    data,
  })

  return res
}

export const getUserList = async (token: string) => {
  const res = await axios({
    method: "post",
    headers: { authorization: token },
    url: `${endPoint}/getUserList`,
    data: {},
  })

  return res
}

export const deleteUser = async (data: { userName: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token },
    url: `${endPoint}/deleteUser`,
    data,
  })

  return res
}

export const addUsertoGroup = async (data: {
  userName: string
  role: string
  token: string
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token },
    url: `${endPoint}/addUsertoGroup`,
    data,
  })

  return res
}

export const editUser = async (data: {
  userName: string
  role: string
  token: string
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token },
    url: `${endPoint}/editUser`,
    data,
  })
  return res
}

export const removeFromGroup = async (data: {
  userName: string
  role: string
  token: string
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token },
    url: `${endPoint}/removeFromGroup`,
    data,
  })

  return res
}

export const campaignResendPassword = async (data: {
  userName: string
  token: string
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token },
    url: `${endPoint}/campaignResendPassword`,
    data,
  })
  return res
}

campaignClient.setFollowContinuationTokens(true)

export { campaignClient, publicCampaignClient, setupApiClient, endPoint }
