import { useMemo, useState } from "react"
import { useQuery } from "react-query"
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes"
import { PageLoader, Table } from "../../Components"
import { TableColumns } from "./ParticipantCol"
import { campaignClient } from "../../api"
import { CSVLink } from "react-csv"

import { endOfMonth } from "date-fns"

import DatePicker from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import "./index.css"
import "react-datepicker/dist/react-datepicker.css"
import { QUESTIONS } from "./questions"
import dayjs from "dayjs"
import { REPORT_BEGINS } from "../Overview"

function Participants() {
  type DateSelectionForm = {
    month: Date
  }
  const nameCase = (name: string | undefined) => (name ? name.charAt(0).toUpperCase() + name.slice(1) : "")

  const { handleSubmit, control } = useForm<DateSelectionForm>({ mode: "onChange" })
  const getMonth = (month: number) => (month + 1).toLocaleString().padStart(2, "0")

  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
    month: getMonth(new Date().getMonth()),
  })
  const [filterTable, setFilterTable] = useState("")

  const { isLoading: isLoadingParticipants, data: participants } = useQuery(["overview", filter], async () => {
    const res: MainDbReturnTypes.Participants = await campaignClient.call("overview", filter)
    return res.participants
  })

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      //let filteredParticipants = participants

      // if (filter) {
      //   filteredParticipants = participants.filter(
      //     (p) =>
      //       new Date(p.creationTime) >= new Date(filter?.month) &&
      //       new Date(p.creationTime) <= endOfMonth(new Date(filter?.month))
      //   )
      // }

      return participants.map(({ participant, answers }: any) => {
        return {
          fullName: nameCase(participant.firstName),

          email: participant.email,

          entryDate: dayjs(participant.creationTime).format("MM/DD/YYYY"),

          entryTime: dayjs(participant.creationTime).format("hh:mm A"),

          reportTime: dayjs(participant.creationTime).format("HH:mm"),

          sessionKey: participant.sessionKey,
          language: participant.preferredLanguage,
          gender: participant.metadata.gender,
          optin: participant.metadata?.optin1 ? "Yes" : "No",
          rules: participant.metadata?.rules ? "Yes" : "No",
          rules3: participant.metadata?.rules3 ? "Yes" : "No",
          rules4: participant.metadata?.rules4 ? "Yes" : "No",

          q1: answers[1].answer ? answers[1].answer : "N/A",
          q2: answers[2].answer ? answers[2].answer : "N/A",
          q3: answers[3].answer ? answers[3].answer.replace("&rsquo;", "'") : "N/A",
          q4: answers[4].answer ? answers[4].answer : "N/A",
          q5: answers[5].answer ? answers[5].answer : "N/A",
          q6: answers[6].answer ? answers[6].answer : "N/A",
          q7: answers[7].answer ? answers[7].answer.replace("&rsquo;", "'") : "N/A",
          q8: answers[8].answer ? answers[8].answer : "N/A",
          q9: answers[9].answer ? answers[9].answer.replace("&rsquo;", "'") : "N/A",
          q10: answers[10].answer ? answers[10].answer : "N/A",
          q11: answers[11].answer ? answers[11].answer : "N/A",
          q12: answers[12].answer ? answers[12].answer : "N/A",
          q13: answers[13].answer ? answers[13].answer.replace("&rsquo;", "'") : "N/A",
          q14: answers[14].answer ? answers[14].answer : "N/A",
          q15: answers[15].answer ? answers[15].answer.replace("&rsquo;", "'") : "N/A",
          q16: answers[16].answer ? answers[16].answer : "N/A",
        }
      })
    }
  }, [participants, filter])

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp) => {
        return {
          Language: rp.language,
          "Entry date": dayjs(rp.entryDate).format("YYYYMMDD"),
          "Entry time": rp.reportTime,
          "Full Name": rp.fullName,
          Email: rp.email,
          Gender: rp.gender,
          [QUESTIONS.optin]: rp.optin,
          [QUESTIONS.q1]: rp.q1,
          [QUESTIONS.q2]: rp.q2,
          [QUESTIONS.q3]: rp.q3,
          [QUESTIONS.q4]: rp.q4,
          [QUESTIONS.q5]: rp.q5,
          [QUESTIONS.q6]: rp.q6,
          [QUESTIONS.q7]: rp.q7,
          [QUESTIONS.q8]: rp.q8,
          [QUESTIONS.q9]: rp.q9,
          [QUESTIONS.q10]: rp.q10,
          [QUESTIONS.q11]: rp.q11,
          [QUESTIONS.q12]: rp.q12,
          [QUESTIONS.q13]: rp.q13,
          [QUESTIONS.q14]: rp.q14,
          [QUESTIONS.q15]: rp.q15,
          [QUESTIONS.q16]: rp.q16,
          [QUESTIONS.rules]: rp.rules,
          [QUESTIONS.rules3]: rp.rules3,
          [QUESTIONS.rules4]: rp.rules4,
        }
      })
    }
  }, [data])

  const columns = TableColumns()

  const handleFilter = async (date: DateSelectionForm) => {
    const year = date.month.getFullYear()
    const month = getMonth(date.month.getMonth())
    setFilter({ month, year })
  }

  if (isLoadingParticipants) {
    return <PageLoader />
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Participants</h2>

        {!!participants?.length && (
          <div className="main__actions">
            <div className="search search--alt">
              <div className="search__row search__row--input">
                <CSVLink
                  filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                  className="btn btn--medium btn--mobile-small"
                  data={reportData ? reportData : ""}
                  asyncOnClick={true}
                  target="_blank">
                  Download Report
                </CSVLink>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="main__body">
        <div className="tabs js-tabs" style={{ display: "flex" }}>
          <form>
            <div className="filter">
              <div className="date__control">
                <Controller
                  control={control}
                  name="month"
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value || new Date()}
                      minDate={new Date(REPORT_BEGINS)}
                      maxDate={new Date()}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                    />
                  )}
                />
              </div>

              <button className="btn" onClick={handleSubmit(handleFilter)}>
                Filter
              </button>
            </div>
            {/* {errors.endDate && (
              <p className="error-message">
                <i className="fas fa-exclamation-circle" /> Invalid Date Range.
              </p>
            )} */}
          </form>

          {!!participants?.length && (
            <div className="search search--alt search--medium main__search">
              <div className="search__row">
                <label htmlFor="q" className="hidden">
                  Search
                </label>
                <input
                  type="search"
                  name="q"
                  id="q"
                  value={filterTable}
                  placeholder="Search"
                  className="search__field"
                  onChange={(e) => setFilterTable(e.target.value)}></input>
                <button className="search__btn">
                  <svg className="ico-search">
                    <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table columns={columns} data={data ? data : []} tablePageSize={15} filterValue={filterTable} />
        </div>
      </div>
      {!participants?.length && <p>Currently there are no any participants to display.</p>}
    </>
  )
}

export default Participants
