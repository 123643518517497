import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "Language",
      accessor: "language",
    },
    {
      Header: "TOTAL PARTICIPANTS",
      accessor: "total",
    },
    {
      Header: "FULL QUIZ COMPLETIONS",
      accessor: "completions",
    },
    {
      Header: "COMPLETION RATE %",
      accessor: "completeRate",
    },
    {
      Header: "QUIZ NOT COMPLETED",
      accessor: "notCompleted",
    },
    {
      Header: "DROP OFF RATE %",
      accessor: "dropOff",
    },
  ];
  return COLUMNS;
};
