import { Route, Navigate } from "react-router-dom";
import { NoMatch, Participants, Participant } from "../Pages";
import { Layout } from "../Components";
import Overview from "../Pages/Overview";

export default (
  <>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/overview" />} />
      <Route path="overview" element={<Overview />} />
      <Route path="participants" element={<Participants />} />
      <Route path="participant/:key" element={<Participant />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </>
);
