export const QUESTIONS = {
  optin:
    "Yes, sign me up for future communications including resource offerings and occasional feedback requests from RBC Future Launch.",
  q1: "Where are you lifting off from?",

  q2: "How many times have you been around the sun?",
  q3: "What's your highest level of training?",
  q4: "When you're not planning your next mission, what keeps you busy? If you're a multitasker, pick more than one.",
  q5: "There's a lot out there. What are you interested in exploring? Select all that apply.",
  q6: "Things don't always go as planned. How do you feel when dealing with stress?",
  q7: "Someone new is about to join your crew. How are you feeling?",
  q8: "If you had a chance, which of these side missions would you take on? Select all that apply.",
  q9: "Is there something stopping you from landing your dream job?",
  q10: "Are you interested in community outreach and supporting others?",
  q11: "How are you feeling about your current job?",
  q12: "There's a whole universe of jobs to explore. What are you searching for?",
  q13: "What's your take on networking?",
  q14: "What interests get your brain thrusters fired up? Select all that apply.",
  q15: "If you'd like to tell us more about you, we&rsquo;d like to share some resources that could help.",
  q16: "Which of these groups do you identify with?",
  rules:
    "I have reviewed, and I consent to the Terms of the RBC Future Launch Discovery Tool.",
  rules3: "I have reviewed, and I consent to the RBC Privacy Policy.",
  rules4:
    "I confirm that I am of the age of majority, or, if I am a minor, that my parent or legal guardian has given consent for me to take this quiz.",
}
