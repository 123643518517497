import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import { QueryClient, QueryClientProvider } from "react-query"
import "@aws-amplify/ui-react/styles.css"
import reportWebVitals from "./reportWebVitals"
import App from "./App"
import { COGNITO_CONFIG } from "./Config"
import { ToastManager } from "./Components/Toast/ToastManager"

Amplify.configure({
  Auth: COGNITO_CONFIG,
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Authenticator.Provider>
        <BrowserRouter>
          <App />
          <ToastManager />
        </BrowserRouter>
      </Authenticator.Provider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
